import * as ReduxActions from "redux-actions"

export const LOADING_END = "bums/common/report/card/action/LOADING_END"

export type LoadingEnd = ReduxActions.Action<void>

export function loadingEnd(): LoadingEnd {
    return {
        type: LOADING_END,
    }
}
