export const EDIT_ROLE_EVENT = "Edit Role Submit"
export const EDIT_CLIENT_EVENT = "Edit Client Submit"
export const EDIT_TITLE_EVENT = "Edit Title Submit"
export const EDIT_DESCRIPTION_EVENT = "Edit Description Submit"
export const EDIT_TYPE_EVENT = "Edit Type Submit"
export const EDIT_NOTIFICATION_EVENT = "Edit Notification Submit"
export const EDIT_FAVOURITE_EVENT = "Edit Favourite"
export const EDIT_FIELD_EVENT = "Edit Field Submit"
export const EDIT_BONUS_SUBMIT = "Edit Bonus Submit"
export const EDIT_PICTURE_EVENT  = "Edit Picture Submit"
export const EDIT_DUPLICATED_EVENT = "Edit Duplicated Submit"
export const ADD_PAYER_EVENT = "Add Payer Submit"
export const EDIT_PAYER_EVENT = "Edit Payer Submit"
export const EDIT_CONTACT_EVENT = "Edit Contact Submit"
export const DELETE_CONTRACTOR_EVENT = "Delete Contractor Submit"
export const EDIT_MASTERTASK_EVENT = "Edit Mastertask Submit"
export const EDIT_BURNING_EVENT = "Edit Burning"
export const EDIT_DEADLINE_EVENT = "Edit Deadline Submit"
export const DELETE_TASK_EVENT = "Delete Task Submit"
export const EDIT_LAYOUT_EVENT = "Edit Layout Submit"
export const DELETE_TRADE_EVENT = "Delete Trade Submit"
export const RUN_TRIGGER_EVENT = "Launch Scenario"
export const EDIT_PRICE_EVENT = "Edit Price"
export const EDIT_PRODUCT_EVENT = "Edit Product Submit"
export const GUEST_MODE_CLICK_EVENT = "Guest Mode Click"
export const GUEST_MODE_SUBMIT_EVENT = "Guest Mode Submit"

export const EVENT_CARD_EVENT_TYPE = "Event Card"
export const EVENT_CARD_OPENED_EVENT = "Opened"
export const EDIT_EVENT_EVENT = "Edit Event Submit"
export const DELETE_EVENT_EVENT = "Delete Event Submit"
export const SUBMIT_EVENT_EVENT = "Submit Event Form"
export const ADD_PARTICIPANT_CLICK_EVENT = "Add Participant Click"
export const ADD_PARTICIPANT_SUBMIT_EVENT = "Add Participant Submit"
export const DELETE_PARTICIPANT_SUBMIT_EVENT = "Delete Participant Submit"
export const CHANGE_TIME = "Change Time"
export const EVENT_CARD = "eventCard"
export const MINI_EVENT_CARD = "miniEventCard"
export const CALENDAR_MOVE = "calendarMove"
export const CALENDAR_LIST_MOVE = "calendarListMove"
export const TAB_CLICK_EVENT = "Tab Click"
export const ADD_MILESTONE_EVENT = "Add Milestone Submit"

export function getCurrentModule() {
    if (window.location.pathname.includes("task")) {
        return "Task"
    } else if (window.location.pathname.includes("crm")) {
        return "Crm"
    } else if (window.location.pathname.includes("staff")) {
        return "Employee"
    } else if (window.location.pathname.includes("deals") || window.location.pathname.includes("bp")) {
        return "Deals"
    }
    return null
}

export function getNow(): number {
    if (window?.performance) {
        return window.performance.now()
    }
    return Date.now()
}
