import {action} from "mobx"
import * as Collections from "src/lib/collections"
import {contentTypeToClassMap} from "src/ContentTypeToClassMap"
import {
    BaseEntity,
    UNKNOWN_ID_PREFIX,
    Id,
    isDateInterval,
    isBaseValue,
    isBaseEntity,
    isFilterEntity,
    isExtraField,
} from "src/lib/entities/types"
import {LoadState} from "src/lib/utils/loadState"
import * as Api from "src/lib/entities/bums"
import {DateOnlyToDate} from "src/lib/utils/intl/dateHelper"
import {Intl, PERSONAL_NAME_ORDER} from "src/lib/utils/intl/Intl"
import {getMessageContactName} from "src/lib/utils/chat"

export type GetEntityFunction = <T extends BaseEntity>(link: T) => T
export type GetListFunction = (listName: string) => Collections.List<BaseEntity>

const changesetMsg: any = require("src/bums/common/changeset/messages.yml")
const libMessages: any = require("src/lib/messages.yml")

/**
 * Отдаёт эндпоинт для типа сущности
 * @param entity
 * @returns {any|string}
 */
export function getEntityTypeEndpoint(entity: BaseEntity | Api.ContentType | string): string {
    const contentType: Api.ContentType | string = typeof entity === "string" ? entity : entity.contentType
    return "/api/v3/" + contentType.charAt(0).toLowerCase() + contentType.slice(1)
}

/**
 * Отдаёт эндпоинт для списка, привязанного к сущности (поле-множество сущности)
 * @param entity
 * @param fieldName
 */
export function getListEndpoint(entity: BaseEntity | Api.ContentType | string, fieldName: string): string {
    return getEntityTypeEndpoint(entity) +
        (typeof entity === "string" ? "" : `/${entity.id}`) +
        `/${fieldName}`
}

/**
 * Отдаёт имя списка, привязанного к сущности (поле-множество сущности)
 * @param entity
 * @param fieldName
 * @returns {string}
 */
export function getListName(entity: BaseEntity | Api.ContentType | string, fieldName: string): string {
    return getListEndpoint(entity, fieldName)
}

/**
 * Returns fetchName, which is used, when guestEntity is added to set (fieldName) in mainEntity.
 * i.e. employee is added to set of auditors in a task.
 * @param mainEntity
 * @param fieldName
 * @returns {string}
 */
export function getFetchNameInSet(mainEntity: BaseEntity, fieldName: string) {
    return `${getListName(mainEntity, fieldName)}`
}

export function getLink<T extends BaseEntity>(entity: T): T {
    if (entity === null) {
        // Null is valid entity value as well
        return null
    }
    if (void 0 === entity.id) {
        throw new Error("Can not extract entityLink from entity without id")
    }
    return {
        id: String(entity.id),
        contentType: entity.contentType,
    } as any
}

export const setEntityFetchState = action((entity: BaseEntity | void, name: string, state: LoadState): void => {
    if (entity && entity._fetchStates) {
        state.isNone()
            ? entity._fetchStates.delete(name)
            : entity._fetchStates.set(name, state)
    }
})

export function getEntityFetchState(entity: BaseEntity | void, fetchName: string) {
    return entity && entity._fetchStates && entity._fetchStates.has(fetchName)
        ? entity._fetchStates.get(fetchName)
        : LoadState.None()
}

export function getEntityFetchStateInSet(mainEntity: BaseEntity, fieldName: string, guestEntity: BaseEntity) {
    return getEntityFetchState(guestEntity, getFetchNameInSet(mainEntity, fieldName))
}

export function isEntityEquals(a: BaseEntity, b: BaseEntity): boolean {
    return a != null && b != null && (a === b || (a.contentType === b.contentType && String(a.id) === String(b.id)))
}

export function getGID(entity: BaseEntity) {
    return `${entity.contentType}/${entity.id}`
}

export function getGUID(entity: BaseEntity) {
    return `${contentTypeToClassMap[entity.contentType]}:${entity.id}`
}

export const getNextUnknownId = (() => {
    let lastUnknownId = 0
    return function(): string {
        return UNKNOWN_ID_PREFIX + (++lastUnknownId)
    }
})()

export function isIdUnknown(id: Id): boolean {
    return 0 === id.indexOf(UNKNOWN_ID_PREFIX)
}

export function validateIntervalTime(interval: Api.IntervalTime): boolean {
    return (Boolean(interval.from) || Boolean(interval.to))
}

export function validateIntervalDates(interval: Api.IntervalDates): boolean {
    return (Boolean(interval.from) || Boolean(interval.to))
}

export function validateDateOnly(date: Api.DateOnly): boolean {
    return (Boolean(date.day) || Boolean(date.month) || Boolean(date.year))
}

export interface Discount extends Api.Discount {
    currency?: Api.Currency
    value?: number,
    valueInMain?: number,
}

export function isDiscount(arg: any): arg is Discount {
    return Boolean(arg) && typeof arg === "object" && arg.contentType === "Discount"
        && Api.Discount.fields.type.enumValues.includes(arg.type)
}

export interface NamedEntity {
    name: string
}
export function isNamedEntity(a: any): a is NamedEntity {
    return Boolean(a) && typeof a === "object" && "name" in a && typeof a.name === "string"
}

export function getEntityName(entity: BaseEntity | Api.EntityDescription, intl?: Intl): string {
    if (!entity) {
        return ""
    }

    if ((Api.isTask(entity) || Api.isProject(entity)) && intl && entity.rights && !entity.rights.read) {
        return intl.formatMessage(libMessages["noAccess"])
    }

    if (Api.isDoc(entity) && entity.actualVersion) {
        return entity.actualVersion.name || ""
    } else if (Api.isEmployee(entity)) {
        if (process.env.REACT_NATIVE && entity.lastName === entity.firstName) {
            return entity.lastName
        }
        // TODO: имеет смысл учитывать локаль для правильного порядка ФИО
        return `${entity.lastName} ${entity.firstName}`
    } else if (Api.isContractorHuman(entity)) {
        return PERSONAL_NAME_ORDER.reduce((resultName: string, fieldName: string) => {   // <- соединяет фио
            const value = entity[fieldName] || ""

            return resultName
                ? `${resultName} ${value}`.trim()
                : value
        }, "")
    } else if (Api.isLeadForm(entity)) {
        return entity.title || ""
    } else if (Api.isFinOperation(entity)) {
        return entity.number || ""
    } else if (Api.isDeal(entity)) {
        return entity.shortDescription
    } else if (Api.isContactInfo(entity)) {
        let typeTranslate = ""
        if (entity.type && intl) {
            typeTranslate = changesetMsg[entity.type] !== void 0 ? intl.formatMessage(changesetMsg[entity.type]) : entity.type
        }
        return typeTranslate + (entity.value === void 0 ? "" : " " + entity.value)
            + (entity.comment === void 0 ? "" : ", " + entity.comment)
    }  else if (Api.isAddress(entity) && intl) {
        if (!entity.type) {
            return intl.formatMessage(changesetMsg["Address"], {case: "nominative"})
        }
        return intl.formatMessage(changesetMsg["Address"], {case: "nominative"})
            + (entity.type.name === void 0 ? "" : " " + entity.type.name)
            + (entity.value === void 0 ? "" : " " + entity.value)
    } else if (Api.isEntityDescription(entity)) {
        return entity.value || ""
    } else if (Api.isDeal(entity)) {
        return entity.shortDescription
    } else if (Api.isProgramState(entity)) {
        return entity.isEntry && entity.entryPointName ? entity.entryPointName : entity.name
    } else if (isNamedEntity(entity)) {
        return entity.name
    } else if (Api.isCallInfo(entity) && intl) {
        //TODO: принято решение порефакторить, а пока просто заглушка
        return intl.formatMessage(changesetMsg["callRecord"])
    } else if (Api.isOffer(entity)) {
        return entity.name
    }  else if (Api.isSmtp(entity) || Api.isMegamail(entity) || Api.isImap(entity)) {
        return entity.email
    } else if (Api.isMenuItem(entity)) {
        return entity.title
    } else if (Api.isTodoFilter(entity)) {
        return entity.title
    } else if (Api.isMessageContact(entity)) {
        return getMessageContactName(entity)
    }

    if (!process.env.REACT_NATIVE) {
        return entity.contentType
    }
}

export function getValueName<T>(value: T, intl?: Intl, showCount = true) {
    if (isBaseEntity(value)) {

        if ((!value.id || isIdUnknown(value.id)) && intl) {
            return intl.formatMessage(libMessages["newEntity"], {contentType: value.contentType})
        }

        if (isFilterEntity(value)) {
            return (!showCount || value.count == null)
                ? `${value.title}`
                : `${value.title} (${value.count})`
        } else if (isExtraField(value)) {
            return value.hrName ? value.hrName : ""
        } else if (Api.isRelationLink(value)) {
            return value.representation ? value.representation : ""
        }

        return getEntityName(value, intl)
    }

    //TODO: Жесткий костыль, чтобы инпут выбора телефона ответсвенного отобразил то, что нужно
    if (isBaseValue(value) && Api.isParticipant(value) && value.contactInfo.length) {
        if (Api.isParticipant(value)) {
            return value.contactInfo.first().value;
        }
    }

    //TODO: И еще костыль чтобы CSmartSelect отобразил email при выборе из списка ContactInfo
    if (isBaseValue(value) && Api.isContactInfo(value)) {
        return value.value;
    }

    if (Api.isMoney(value) || isDiscount(value)) {
        return null == value.value ? "0" : value.value.toString()
    }

    return typeof(value) === "object" ? "" : String(value)
}

export function getFetchNamespace(fetchName: string): string {
    let index = fetchName.indexOf(".")
    return index === -1 ? fetchName : fetchName.substring(0, index)
}

// Returns DateTime value of todo start date or time
export function getTodoStart(todo: Api.Todo): Date {
    const {when} = todo
    if (Api.isDateOnly(when)) {
        return DateOnlyToDate(when)
    }
    if (when instanceof Date) {
        return when
    }
    if (Api.isIntervalDates(when) && when.from) {
        return DateOnlyToDate(when.from)
    }
    if (Api.isIntervalTime(when) && when.from) {
        return when.from
    }
    return null
}
export function getEntityFromSeriralizedJSON(json: string) {
    return JSON.parse(json, (key, value) => {
        if (key) {
            // восстановление дат
            if (typeof value === "string") {
                /** @see https://msdn.microsoft.com/ru-ru/library/cc836466(v=vs.94).aspx */
                const a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/.exec(value)
                if (a) {
                    return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]))
                }
            }

            return value
        }

        return value
    })
}
export function prepareEntityToSave(entity: any): any {
    return prepareValueToSave(entity, {visited: new Set(), value: entity})
}

function prepareValueToSave (
    val: any,
    state: {
        visited: Set<any>,
        value: any,
        parent?: any
    }
): any {
    if (val instanceof Date) {
        return {
            contentType: "DateTime",
            value: val.toISOString()
        }
    } else if (isDateInterval(val)) {
        return val.denormalize()
    } else if (null != val && typeof val === "object" && typeof val[Symbol.iterator] === "function") {

        return [...val].map(v =>
            prepareValueToSave(v, {
                visited: state.visited,
                value: v,
                parent: state
            })
        )
    } else if (isBaseValue(val)) {
        if (state.visited.has(val) && "id" in val) {
            return getLink(val)
        }
        state.visited.add(val)
        val = Object.assign({}, val)
        delete val._fetchStates
        delete val._version
        delete val._fieldValueVersions
        if (val.id && isIdUnknown(val.id)) {
            delete val.id
        }
        // Если текущее значение может быть сконверчено в ссылку, оно должно быть сконверчено в ссылку.
        if (val.id && state.parent) {
            return getLink(val)
        }

        for (const key in val) {
            val[key] = prepareValueToSave(val[key], {
                visited: state.visited,
                value: val[key],
                parent: state
            })
        }
        return val
    } else if (null != val && typeof val === "object") {
        const target: any = {}
        for (const key in val) {
            target[key] = prepareValueToSave(val[key], {
                visited: state.visited,
                value: val[key],
                parent: state
            })
        }
        return target
    } else {
        return val
    }
}

const imageExtensions = new Set([
    "png",
    "jpeg",
    "bmp",
    "webp",
    "gif",
    "jpg",
])

export function isImageExtension(extension: string): boolean {
    return imageExtensions.has(extension)
}

export function isImage(file: Api.File): boolean {
    return !!file.extension && isImageExtension(file.extension)
}

export const AUDIO_MESSAGE_PREFIX = "megaplan_audiomessage_"

export function isAudioMessage(file: Api.File) {
    return Api.isFile(file) && file.name.includes(AUDIO_MESSAGE_PREFIX)
}

export function subjectName(subject: BaseEntity, intl?: Intl): string {
    let name = ""
    if (Api.isDeal(subject)) {
        const dealName = getEntityName(subject)
        name = dealName ? dealName : renderDealCaption(subject)
    } else {
        name = getEntityName(subject, intl)
    }
    return name
}

function renderDealCaption(entity: Api.Deal) {
    let name = ""

    if (entity.number) {
        name = `${entity.number}.`
    }

    if (entity.name) {
        name += ` ${entity.name}`
    }

    return name
}

//ссылки на приложения
export const GOOGLE_PLAY_LINK = "https://play.google.com/store/apps/details?id=ru.megaplan.megaplan3app"
export const APP_STORE_LINK = "https://itunes.apple.com/ru/app/id1321856023"
