/** Типы для таблицы */
export interface BaseCellContent {
    type: string
    rowNumber?: boolean
}
export interface EmptyCellContent extends BaseCellContent {
}

export interface TextCellContent extends BaseCellContent {
    text: string
}

export interface NumberCellContent extends BaseCellContent {
    number: string | JSX.Element
}

export interface LinkCellContent extends TextCellContent {
    href: string
}

export interface SpinnerCellContent extends BaseCellContent {
    colspan: number
}

export interface ButtonCellContent extends TextCellContent {
    onClick: () => void
    isLoading: boolean
}

export function isLinkCell(cell: any): cell is LinkCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "link"
}

export function isTextCell(cell: any): cell is TextCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "text"
}

export function isNumberCell(cell: any): cell is NumberCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "number"
}

export function isButtonCell(cell: any): cell is ButtonCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "button"
}

export function textCell(text: string, rowNumber = false): TextCellContent {
    return {
        text,
        rowNumber,
        type: "text"
    }
}

export function numberCell(number: string | JSX.Element): NumberCellContent {
    return {
        number,
        type: "number"
    }
}

export function spinnerCell(colspan: number): SpinnerCellContent {
    return {
        colspan,
        type: "spinner"
    }
}

export function linkCell(text: string, href: string, rowNumber = false): LinkCellContent {
    return {
        text,
        href,
        rowNumber,
        type: "link"
    }
}

export function isEmptyCell(cell: any): cell is EmptyCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "empty"
}

export function isSpinnerCell(cell: any): cell is SpinnerCellContent {
    return "object" === typeof cell && "type" in cell && cell.type === "spinner"
}
