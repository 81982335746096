import {PurchaseEvent, Transport} from "./Transport"
import {getNow} from "./utils"

export interface TrackerInterface {
    trackEvent: (type: string, key: string, value?: TrackEventValueType) => void,
}

export type TrackEventValueType = string | {[key: string]: string | number} | null | {}

export class Tracker implements TrackerInterface {

    protected timings = new Map<string, [Date, number]>()

    constructor(
        private transport: Transport
    ) {}

    public trackEvent = (type: string, key: string, value: TrackEventValueType = null, channels?: string[]) => {
        const trackValue = value instanceof Object
            ? JSON.stringify(value)
            : value

        this.transport.trackEvent(type, key, trackValue, channels)
    }

    public trackScreen = (name: string) => {
        this.transport.trackScreen(name)
    }

    public trackTiming = (type: string, name: string) => {
        this.timings.set(type + name, [new Date(), getNow()])
    }

    public trackTimingEnd = (type: string, name: string) => {
        const [startDate, timestamp] = this.timings.get(type + name) ?? []
        const diff = getNow() - timestamp
        if (startDate && diff) {
            this.transport.trackTiming(type, name, diff, startDate)
        }
        this.timings.delete(type + name)
    }

    /**
     * Яндекс метрика
     * @param id идентификатор цели
     */
    public yaTrack = (id: string) => {
        if (window.yaCounter20277427) {
            window.yaCounter20277427.reachGoal(id)
        }
    }

    /**
     * googleAnalitics
     * @param id идентификатор цели
     */
    public gaTrack = (id: string) => {
        if (typeof window.gtag !== "undefined") {
            window.gtag("event", id)
        }
    }

    public trackPurchase = (event: PurchaseEvent) => {
        this.transport.trackPurchase(event)
    }
}
