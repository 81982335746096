import * as ReduxActions from "redux-actions"

export type LoadingStart = ReduxActions.Action<{value: string}>

export const LOADING_START = "bums/common/report/card/action/LOADING_START"

export function loadingStart(value: string): LoadingStart {
    return {
        type: LOADING_START,
        payload: {
            value
        }
    }
}
