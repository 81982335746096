import {observer} from "mobx-react"
import * as React from "react"
require("./CThemeColor.styl")

/**
 * Компонент для подключения css переменных
 */

export const CThemeColor: React.FC = observer(() => {
    return null
})
