import moment from "moment"
import {isEqual} from "lodash"
import {PlainForm} from "src/lib/utils/form/types"
import {Employee, getLink, IntervalDates, IntervalTime, isEntityEquals, Todo} from "src/lib/entities/api"
import {DateOnly} from "src/lib/entities/bums/dateOnly"
import {List} from "src/lib/collections"
import {getTomorrow} from "src/lib/utils/date"
import {DateToDateOnly} from "src/lib/utils/intl/dateHelper"
import {TodoListType} from "src/bums/todo/list/types"
import {TrackingParams} from "src/lib/tracking";
import {EDIT_DESCRIPTION_EVENT, EDIT_TITLE_EVENT, EDIT_TYPE_EVENT} from "src/bums/common/stores/Tracker/utils"
import {Interval} from "src/bums/todo/stores/ParticipantsTodos"

/**
 * Settings
 */
export const SWITCH_TODO_CARD_SETTING_NAME = "bums.todo.new.card"
export type PartialTodoForm<K extends keyof Employee> = PlainForm<Pick<Employee, K>>

const lettersRegExp = /[a-z]|[а-я]/i
export function containLetters(string: string) {
    return lettersRegExp.test(string)
}

export function getPeriodIntervalDates(
    period: TodoListType.MONTH | TodoListType.WEEK | TodoListType.DAY ,
    date: Date
): IntervalDates {
    return {
        ...IntervalDates.newObject,
        from: getPeriodStartDateOnly(period, date),
        to: getPeriodEndDateOnly(period, date)
    }
}

export function getPeriodIntervalTime(
    period: TodoListType.MONTH | TodoListType.WEEK | TodoListType.DAY ,
    date: Date
): IntervalTime {
    return {
        ...IntervalTime.newObject,
        from: getPeriodStart(period, date),
        to: getPeriodEnd(period, date)
    }
}

export function getPeriodStart(period: "day"|"week"|"month", date: Date): Date {
    let start = date.clone()

    if (period === "week") {
        const startWeek = moment(date).startOf("isoWeek").toDate()
        start = startWeek.clearTime()
    }

    if (period === "month") {
        const startMonth = moment(date).startOf("month").toDate()
        start = startMonth.clearTime()
    }

    return start
}

export function getPeriodStartDateOnly(period: "day"|"week"|"month", date: Date): DateOnly {
    return DateToDateOnly(getPeriodStart(period, date))
}

export function getPeriodEnd(period: "day"|"week"|"month", date: Date): Date {
    let end = getTomorrow(date)

    if (period === "week") {
        end = moment(date).endOf("isoWeek").toDate().clearTime()
    }

    if (period === "month") {
        end = moment(date).endOf("month").toDate().clearTime()
    }

    return end
}

export function getPeriodEndDateOnly(period: "day"|"week"|"month", date: Date): DateOnly {
    return DateToDateOnly(getPeriodEnd(period, date))
}


export function getFilterEmployees(employees: List<Employee>) {
    return employees.map(getLink)
}

/*
* название раздела в делах зависит от выбранного периода
*/

export function getSectionNameForCalendarByPeriod(periodName: string) {
    return periodName === "list" ? "todo" : "time"
}

export const CALENDAR_EVENT = "Calendar"
export const SWITCH_TAB_EVENT = "Switch Tab"
export const MOVE_CALENDAR = "Move Calendar"
export const EXPAND_HOURS = "Expand Hours"
export const PARTICIPANTS_CLICK = "Participants Click"
export const PARTICIPANTS_CANCEL = "Participants Cancel"
export const PARTICIPANTS_SUBMIT = "Participants Submit"
export const PARTICIPANTS_ADD = "Participants Add"
export const FROM_CALENDAR_LIST_QUICK = "fromCalendarListQuick"
export const EVENT_CARD = "eventCard"
export const EVENT_LIST_CHECK_BOX = "eventListCheckBox"
export const CALENDAR_CHECK_BOX = "calendarCheckBox"
export const MINI_CARD = "miniCard"
export const MINI_CARD_NEW = "miniCardNew"
export const FULL_CARD = "fullCard"
export const FROM_CALENDAR_BUTTON = "fromCalendarButton"
export const  MINI_CALENDAR_CLICK = "Mini Calendar Click"
export const  EXTRA_CALENDARS_EXPAND = "Extra Calendars Expand"
export const  EXTRA_CALENDAR_CLICK = "Extra Calendar Click"
export const  EXTRA_CALENDAR_SUBMIT = "Extra Calendar Submit"
export const  PLACE_CLICK = "Place Click"
export const  PLACE_SUBMIT = "Place Submit"
export const ADDITIONAL_LIST_CLICK = "Additional List Click"
export const OPEN_TODO_LIST_SETTINGS = "Open Todo List Settings"
export const ADD_TODO_LIST_SUBMIT = "Add Todo List Submit"
export const DELETE_TODO_LIST_SUBMIT = "Delete Todo List Submit"
export const MOVE_TODO_LIST = "Move Todo List"
export const CLICK_SHOW_TODO_LIST = "Click Show Todo List"
export const SWITCH_TODO_LIST = "Switch Todo List"
export const CALENDAR_OPENED_EVENT = "Opened"
export const LINK_ENTITY_EVENT = "Link Entity Submit"
export const EDIT_NOTIFICATIONS_EVENT = "Edit Notifications Submit"
export const EDIT_PLACE_EVENT = "Edit Place Submit"
export const CHANGE_TASK_VIEW_EVENT = "Change Task View"

export function getUpdateTodoEvents(oldTodo: Todo, newTodo: Todo): TrackingParams[] {
    const events: TrackingParams[] = []
    const commonParams = {method: MINI_CARD_NEW}

    if (oldTodo.name !== newTodo.name) {
        events.push({
            event: EDIT_TITLE_EVENT,
            params: commonParams
        })
    }

    if (oldTodo.description !== newTodo.description) {
        events.push({
            event: EDIT_DESCRIPTION_EVENT,
            params: commonParams
        })
    }

    if (!isEntityEquals(oldTodo.category, newTodo.category)) {
        events.push({
            event: EDIT_TYPE_EVENT,
            params: commonParams
        })
    }

    if (!isEntityEquals(oldTodo.place, newTodo.place)) {
        events.push({
            event: EDIT_PLACE_EVENT,
            params: commonParams
        })
    }

    return events
}

// проверим относится ли интервал к одной дате
export function isOneDayInterval(value: Interval) {
    if (!value) {
        return false
    }
    let tomorrow = new Date(value.from)
    tomorrow.setDate(value.from.getDate() + 1)

    //если даты совпадаю
    return isEqual(DateToDateOnly(value.from), DateToDateOnly(value.to))
        // если разница дат 1 день и вторая со временем 00:00
        || isEqual(DateToDateOnly(tomorrow), DateToDateOnly(value.to)) && value.to.getHours() === 0 && value.to.getMinutes() === 0
}
