module.exports = function(locale, cb) {
    switch (locale) {
        case "az":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=az!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "be":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=be!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "cs":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=cs!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "de":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=de!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "en":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=en!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "fr":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=fr!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "id":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=id!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "kk":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=kk!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "ru":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=ru!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "tr":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=tr!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "uk":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=uk!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
        case "vi":
            require.ensure([], function(require) { cb(require("!!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/globalize-config-loader/lib/compiler.js?sync=false&locale=vi!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/json-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/.build/extensions/extract-globalize-formatters-loader/index.js!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/thread-loader/dist/cjs.js??ref--4-0!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/babel-loader/lib/index.js??ref--4-1!/builds/Pyz7Ax65/0/megaplan/megaplan/front/node_modules/ts-loader/index.js??ref--4-2!/builds/Pyz7Ax65/0/megaplan/megaplan/front/src/web/lib/utils/intl/formatters.ts")); });
            break;
    default:
        throw new Error("Cannot find module " + locale + ".");
    }
}