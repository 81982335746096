import * as React from "react"
import {TableReportData, BaseRow, isFirstColumn, isGroupRow, isSumUpCell, isRowList} from "../entities"
import {Row, Renderer} from "rc-table"
import * as Collections from "src/lib/collections"
import {RowList, GroupRow, isGroupRowList, GroupRowList, SumUpCellContent, ColumnList, BaseColumn} from "../entities"
import {Chart, ChartDataEntity, ChartDataExtendedFields} from "./chartEntities"
import {complexCell, isComplexCell} from "../entities"
import {BaseCellContent, isTextCell, textCell} from "src/lib/components/CRcTable/entities"
import {timePeriodShortHr, Intl, FormattedNumber, hoursMinutesPeriodShortHr} from "src/lib/utils/intl"


function formatRow(row: BaseRow, index: number, keyPrefix = "", level = 0) {
    let formattedRow: Row<BaseCellContent> = Object.assign({key: `${keyPrefix}_${index}`, index}, row.cells)

    formattedRow.className = `level-${level}`

    if (isGroupRow(row)) {
        if (row.subRows) {
            formattedRow.children = row.subRows
                .map((rr: BaseRow, subIndex: number) => formatRow(rr, subIndex, `${keyPrefix}${index}_`, level + 1))
        }
        formattedRow.className += " group-header"
        formattedRow.collapsed = row.collapsed
    } else {
        const classLevel = (level === 0) ? 0 : level - 1
        formattedRow.className = `level-${classLevel}`
    }

    formattedRow.groupHeader = isGroupRow(row)

    return formattedRow
}

function findRowById(rows: RowList|Array<BaseRow>, id: string): GroupRow {
    const rowsArray: Array<BaseRow> = isRowList(rows) ? rows.toArray() : rows
    const arr1 = rowsArray.map(row => {
        if (isGroupRow(row)) {
            if (row.id === id) {
                return row
            } else if (row.subRows) {
                return findRowById(row.subRows, id)
            }
        }

        return null
    })

    return arr1.filter(row => !!row).pop()
}

function mergeChunk(row: GroupRow, chunk: GroupRow) {
    if (!row.subRows) {
        row.subRows = []
    }
    if (!chunk.subRows) {
        chunk.subRows = []
    }
    if (row.subRows.length && isGroupRow(row.subRows[0])) {
        // если это снова группировка
        const subRows = row.subRows as Array<GroupRow>
        chunk.subRows.forEach(subChunk => {
            if (isGroupRow(subChunk)) {
                mergeChunkToRows(subRows, subChunk)
            }
        })
    } else {
        row.subRows = row.subRows.concat(chunk.subRows)
    }
}

function mergeChunkToRows(rows: GroupRowList|Array<GroupRow>, chunk: GroupRow) {
    let row: GroupRow
    if (row = findRowById(rows, chunk.id)) {
        mergeChunk(row, chunk)
        return true
    } else if (row = findRowById(rows, chunk.parentId)) {
        // если указант parentId, примержим прямо в subRows найденной родительской группы
        if (!row.subRows) {
            row.subRows = []
        }
        row.subRows.push(chunk)
        return true
    }

    return false
}

function mergeGroupChunks(rows: GroupRowList, chunks: GroupRowList) {
    chunks.forEach(chunk => {
        const changed = mergeChunkToRows(rows, chunk)
        // если chunk никуда не примержен, примержим его в rows
        if (!changed) {
            rows = rows.push(chunk)
        }
    })
    return rows
}

export function mergeChunks(rows: RowList, chunks: RowList): RowList {
    if (isGroupRowList(rows)) {
        if (isGroupRowList(chunks)) {
            return mergeGroupChunks(rows, chunks)
        } else {
            // такого быть не должно, но обработаем на всякий случай, мы же правильные пацаны
            return rows
        }
    } else {
        // если у нас данные без группировки, тупо смержим поля rows
        return rows.concat(chunks).reduce(
            (reduction, value) => reduction.concat(value),
            Collections.List<BaseRow>()
        )
    }
}

function filterGroups(rows: Array<Row<BaseCellContent>>) {
    let groups: Array<Row<BaseCellContent>> = []
    rows.forEach((row) => {
        if (row.children && row.children.length >= 0) {
            groups.push(row)
            groups = groups.concat(filterGroups(row.children))
        }
    })

    return groups
}

export function reportDataFormat(json: TableReportData,
                                 renderer: Renderer<BaseCellContent>,
                                 addingToRow?: (row: GroupRow | BaseRow) => GroupRow | BaseRow) {

    const columns = json.columns.map((column) => {
            let className = ""
            if (column.align) {
                className = column.align
            }
            if (column.nowrap) {
                className = `${className} no-wrap`
            }
            if (isFirstColumn(column)) {
                className = `${className} first-column`
            }
            return {
                title: column.title,
                dataIndex: column.name,
                key: column.name,
                className: className,
                render: renderer,
            }
        }
    )

    let completeData: Collections.List<BaseRow | GroupRow> = json.rows
    completeData = addingToRow ? completeData.map(addingToRow) : completeData
    const data = completeData.map((row, index) => formatRow(row, index)).toArray()

    return {
        columns: columns.toArray(),
        data: data,
        groups: filterGroups(data),
        chartData: prepareChartData(completeData, json.columns)
    }
}

function prepareChartData(rows: RowList, columns: ColumnList): Chart {
    if (rows.length === 0 || !isGroupRowList(rows)) {
        return []
    }

    const firstColumn = columns.filter((column) => isFirstColumn(column)).first()
    // карта имя колонки - подпись
    const columnNames: {[index: string]: any} = {}
    columns.forEach((column) => {
        columnNames[column.name] = {
            "title": column.title,
            "isInteger": true,
            "isDuration": false
        }
    })
    let isLeveled = false
    // соберем подытоживание из всех ячеек
    let dataEntities = Collections.Map<string, Array<ChartDataEntity>>()
    let extendedFields: ChartDataExtendedFields = {}
    rows.forEach((row: GroupRow, groupIndex: number) => {
        const cellsMap = Collections.Map(row.cells)
        // карта index => ChartDataEntity
        let rowDataEntries = cellsMap.filter(isSumUpCell)
            .map((cell: SumUpCellContent, index: string) => {
                    let ret: any = {
                        title: row.value,
                        value: cell.sum,
                        index: groupIndex
                    }
                    columnNames[index].isInteger = cell.isInteger
                    columnNames[index].isDuration = cell.isDuration
                    const subRows = Collections.List(row.subRows)
                    if (isGroupRowList(subRows)) {
                        subRows.forEach((subRow: GroupRow) => {
                            const cellSum = subRow.cells[index]
                            if (isSumUpCell(cellSum)) {
                                ret[subRow.value] = cellSum.sum
                                extendedFields[subRow.value] = subRow.value
                            }
                        })
                        isLeveled = true
                    }
                    return ret
                }
            )

        // если стоит галочка "считать количество в группах", также возьмем эти данные для графиков
        if (firstColumn && row.count) {
            const index = firstColumn.name
            let ret: any = {
                title: row.value,
                value: row.count,
                index: groupIndex
            }
            const subRows = Collections.List(row.subRows)
            if (isGroupRowList(subRows)) {
                subRows.forEach((subRow: GroupRow) => {
                    const cell = subRow.cells[index]
                    if (isComplexCell(cell)) {
                        const cellValue = cell.values[0]
                        if (isTextCell(cellValue)) {
                            ret[cellValue.text] = subRow.count
                            extendedFields[cellValue.text] = cellValue.text
                        }
                    }
                })
                isLeveled = true
            }
            rowDataEntries = rowDataEntries.set(index, ret)
        }

        rowDataEntries.forEach((chartDataEntity, index) => {
            const chartDataEntities = dataEntities.has(index) ? dataEntities.get(index) : []
            dataEntities = dataEntities.set(index, chartDataEntities.concat(chartDataEntity))
        })
    })

    return dataEntities.map((chartDataEntities, index) => {
        return {
            key: index,
            name: columnNames[index].title ? columnNames[index].title : "",
            data: chartDataEntities,
            isLeveled: isLeveled,
            extendedFields: extendedFields,
            isInteger: columnNames[index].isInteger,
            isDuration: columnNames[index].isDuration
        }
    }).toArray()
}

export function prepareTotalData(json: TableReportData,
                                 renderer: Renderer<BaseCellContent>,
                                 transtate: (text: string) => string) {
    if (!json.total) {
        return
    }

    const cellsMap = Collections.Map(json.total.cells)
    const sumupColumnKeys = cellsMap.filter(isSumUpCell).map((cell, key) => key)
    const captionColumn = {
        type: "first",
        name: "__caption-column",
        className: "caption-column",
        title: transtate("ReportTotals"),
        row1: textCell(transtate("Total") + ":"),
        row2: complexCell([
            textCell(transtate("Minimum") + ","),
            textCell(transtate("average") + ","),
            textCell(transtate("maximum"))
        ]),
    }
    function isCaptionColumn(arg: any): arg is typeof captionColumn {
        return arg != null && arg.type === "first";
    }
    const firstColumnKey = json.columns.filter(isFirstColumn).map((column) => column.name).first()

    const columns = Collections.List<(typeof captionColumn) | BaseColumn>([captionColumn]).concat(json.columns)
        .filter((column) => sumupColumnKeys.includes(column.name) || isFirstColumn(column))
        .map((column) => ({
            title: column.title,
            dataIndex: column.name,
            className: isCaptionColumn(column) ? column.className : "",
            key: column.name,
            render: renderer,
        }))
        .toArray()

    // соберем строку с суммами: пройдемся по cells строки и обнулим поля avg, min, max
    const dataSums = formatRow(
        Object.assign({}, json.total, {
            cells: cellsMap
            // добавим первую ячейку первой колонки
                .set(captionColumn.name, captionColumn.row1)
                // добавим первую ячейку второй колонки
                .set(firstColumnKey, textCell("" + json.total.count))
                .map((cell) => Object.assign({type: cell.type}, cell, {
                    avg: null,
                    min: null,
                    max: null
                }))
                .toObject()
        }),
        1
    )

    let data = [dataSums]

    // соберем строку с подытоживаниями без сумм
    if (json.total.additionalMetrics) {
        const dataSumUps = formatRow(
            Object.assign({}, json.total, {
                cells: cellsMap
                // добавим первую ячейку
                    .set(captionColumn.name, captionColumn.row2)
                    // добавим первую ячейку второй колонки
                    .set(firstColumnKey, textCell(""))
                    .map((cell) => Object.assign({type: cell.type}, cell, {sum: null}))
                    .toObject()
            }),
            2
        )
        data.push(dataSumUps)
    }

    return {
        columns,
        data
    }
}

export function renderSumUpValue(intl: Intl, value: number, isDuration: boolean, workingHours: boolean): string | JSX.Element {
    if (isDuration) {
        return (workingHours ? hoursMinutesPeriodShortHr(intl) : timePeriodShortHr(intl))(value)
    } else {
        return React.createElement(FormattedNumber, {value})
    }
}
