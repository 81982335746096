import {observable, computed, action, reaction, observe, when, IValueDidChange, runInAction} from "mobx"
import {isEmpty, debounce, Cancelable, noop} from "lodash"
import {BaseEntity, RequestOptions, ListDirection, ApiErrorResponse, BaseValue} from "../types"
import {ListItem} from "./types"
import {prepareEntityToSave} from "../utils"
import * as Collections from "src/lib/collections"
import {ObservableListClass} from "src/lib/collections/ListClass"
import {bindArg} from "src/lib/utils/func"
import {fromPromise, PromiseBasedObservable} from "src/lib/utils/fromPromise"
import {EntitiesList} from "src/lib/entities/store/EntitiesList"
import {ApiStore} from "src/lib/entities/store/ApiStore"

export const LIST_TIMEOUT_REMOVE = process.env.REACT_NATIVE ? 50000 : 300000 // 5 минут

export function generateHash(endpoint: string, options: RequestOptions = {}, hashModifier?: string) {
    if (!endpoint) {
        return void 0
    }

    const chunks: string[] = [endpoint]

    if (!isEmpty(options)) {
        options = prepareEntityToSave(options)

        if ("fields" in options && Array.isArray(options.fields)) {
            options = {
                ...options,
                fields: [...options.fields].sort()
            }
        }

        chunks.push(`hash:${JSON.stringify(prepareEntityToSave(options))}`)
    }

    if (hashModifier) {
        chunks.push(`modifier:${hashModifier}`)
    }

    return chunks.join(":")
}

export namespace ListStore {
    export interface Settings<T extends BaseEntity> {
        endpoint: string
        hashModifier?: string
        options?: RequestOptions
        limit?: number
        pageWith?: T
        preloadedListName?: string
        onError?: (error?: ApiErrorResponse) => void
        onSuccess?: () => void
    }

    export type SettingFactory<T extends BaseEntity> = ListStore.Settings<T> | void
}

export class ListStore<T extends BaseEntity> {

    private $apiStore: ApiStore

    private $hashCache = new Set<string>()

    @observable
    private $endpoint: string

    @observable
    private $hashModifier: string

    private $limit: number

    private $pageWith: T
    private $preloadedListName: string

    private $disposeHash: () => void
    private $disposeSettingsReaction: () => void
    private $disposeFullLoad: () => void
    private $debouncedFetchList: (() => void) & Cancelable
    private $onError: (error?: ApiErrorResponse) => void
    private $onSuccess: () => void

    private $fromPromiseSettings: PromiseBasedObservable<ListStore.SettingFactory<T>>

    private $isCompleted: PromiseBasedObservable<void>

    @observable.struct
    private $options: RequestOptions = {}

    constructor(
        apiStore: ApiStore,
        settingsFactory: () => ListStore.SettingFactory<T> | PromiseBasedObservable<ListStore.SettingFactory<T>>,
        private $disposeTimeout = LIST_TIMEOUT_REMOVE,
        private $updateTimeout = 100
    ) {
        this.$apiStore = apiStore

        this.$fromPromiseSettings = fromPromise(() => settingsFactory()) as PromiseBasedObservable<ListStore.SettingFactory<T>>

        this.$isCompleted = fromPromise(() => this.whenComplete())

        this.$disposeSettingsReaction = reaction(
            () => this.createSettignsFromFactory(),
            this.settingsReaction,
            {name: "ListStore.settings"}
        )
        this.settingsReaction(this.createSettignsFromFactory())
        this.init()
    }

    private createSettignsFromFactory(): ListStore.Settings<T> {
        try {
            const settings = this.$fromPromiseSettings

            if (settings.state !== "fulfilled") {
                return
            }

            if (!settings.value) {
                return
            }

            return {
                ...settings.value,
                options: prepareEntityToSave(settings.value.options)
            }
        } catch (e) {
            console.error(e)
        }
    }

    private settingsReaction = (settings: ListStore.Settings<T> | void) => {
        try {
            if (!settings) {
                this.setEndpoint(void 0)
                this.setOptions(void 0)
                this.setHashModifier(void 0)
                return
            }
            this.setEndpoint(settings.endpoint)
            this.setOptions(settings.options)
            this.setHashModifier(settings.hashModifier)
            this.$limit = settings.limit
            this.$pageWith = settings.pageWith
            this.$preloadedListName = settings.preloadedListName
            this.$onSuccess = settings.onSuccess
            this.$onError = settings.onError
        } catch (e) {
            console.error(e)
        }
    }

    private init = () => {
        this.$debouncedFetchList = debounce(this.fetchListIfNeeded, this.$updateTimeout)
        this.$disposeHash = observe(this, "hash", this.hashReaction)
        this.hashReaction({
            object: this,
            type: "update",
            newValue: this.hash,
            oldValue: void 0
        })
    }

    private hashReaction = (change: IValueDidChange<string>) => {
        if (change.newValue !== void 0) {
            void this.updateListState(change)
            if (change.oldValue === void 0) {
                this.fetchListIfNeeded()
            } else {
                this.$debouncedFetchList()
            }
        }
    }

    public supportFullLoad = () => {
        void this.fullLoadList().then(() => {
            this.$disposeFullLoad = reaction(
                () => this.originalItems.length,
                (value) => {
                    if (this.loadStateNext.isCompleted() && this.hasMoreNext) {
                        void this.fullLoadList()
                    }
                }
            )
        })
    }

    private fullLoadList = async () => {
        if (this.endpoint === void 0) {
            return
        }
        await this.whenComplete()
        if (this.hasMoreNext) {
            this.loadNext()
            await this.fullLoadList()
        }
    }

    public whenComplete = async () => {
        await this.$fromPromiseSettings.promise
        await (new Promise(resolve => {
            when(
                () => {
                    const lsn = this.loadStateNext
                    const lsp = this.loadStatePrev
                    return (lsn.isCompleted() && lsp.isCompleted()) ||
                           (lsn.isCompleted() && lsp.isNone()) ||
                           (lsp.isCompleted() && lsn.isNone()) ||
                           (lsp.isNone() && lsn.isNone())
                },
                () => resolve(),
            )
        }))
    }

    public get endpoint() {
        return this.$endpoint
    }

    public get pageWith() {
        return this.$pageWith
    }

    public setPageWith(entity: T) {
        this.$pageWith = entity
    }

    @action
    public setEndpoint = (endpoint: string) => {
        this.$endpoint = endpoint
    }

    @action
    private setHashModifier = (hashModifier: string) => {
        this.$hashModifier = hashModifier
    }

    @computed
    public get isCompleted() {
        return this.$isCompleted.state === "fulfilled"
    }

    @computed
    public get hash() {
        return generateHash(this.$endpoint, this.$options, this.$hashModifier)
    }

    @computed
    public get options() {
        return Object.assign({}, this.$options)
    }

    public get paginationOptions() {
        return Object.assign({},
            this.$limit !== void 0 ? {limit: this.$limit} : void 0,
            this.$pageWith ? {pageWith: this.$pageWith} : void 0
        )
    }

    @computed
    public get totalItemsCount() {
        return this.list.invalidatedTotalItemsCount || this.list.totalItemsCount
    }

    @computed
    public get loadStateNext() {
        return this.list.loadStateNext
    }

    @computed
    public get loadStatePrev() {
        return this.list.loadStatePrev
    }

    @computed
    public get hasMoreNext() {
        return this.list.hasMoreNext
    }

    @computed
    public get hasMorePrev() {
        return this.list.hasMorePrev
    }

    @action
    public setOptions = (options: RequestOptions) => {
        this.$options = options
    }

    @action
    public updateOptions = (options: RequestOptions) => {
        this.$options = Object.assign({}, this.$options, options)
    }

    @action
    private updateListState = async (change: IValueDidChange<string>) => {
        const {newValue, oldValue} = change
        let isLink = false

        if (newValue !== void 0) {
            this.$hashCache.add(newValue)
        }

        if (oldValue !== void 0) {
            // если списка с новым hash не существует, создаем и копируем в него старые элементы
            if (newValue && !this.$apiStore.getLists().has(newValue)) {
                const newList = new EntitiesList()
                const oldList = this.$apiStore.getList(oldValue)
                newList.items = new ObservableListClass((oldList.items.toArray()))
                newList.invalidatedItems = oldList.invalidatedItems
                newList.loadedEndpoint = oldValue.split(":hash:")[0]
                this.$apiStore.getLists().set(newValue, newList)
            } else if (newValue) {
                isLink = true
            }
            // при смене опций, снижаем счетчик, так этот список, скорее всего, больше использоваться не будет текущим стором
            // если старый список больше никто не использует - удаляем
            this.destroyLinkToList(oldValue)
        } else {
            if (this.$preloadedListName) {
                await this.$apiStore.copyList(newValue, this.$preloadedListName)
                isLink = true
            } else if (!this.$apiStore.getLists().has(newValue)) {
                this.$apiStore.getLists().set(newValue, new EntitiesList())
            } else {
                isLink = true
            }
        }

        runInAction(() => {
            this.$apiStore.getLists().get(newValue).usedCount++
            // Если список изначально ссылается на другой, то нужно подписать обработчики на его загрузку
            if (isLink) {
                void this.subscribeToLoadHandlers()
            }
        })
    }

    private fetchListIfNeeded = () => {
        // Загрузка списка, если он не был загружен.
        if (this.list.loadStateNext.isNone() && this.list.loadStatePrev.isNone()) {
            this.load()
        }
    }

    @action
    public dispose = (forceClear: boolean = false) => {
        if (this.$disposeHash) {
            this.$disposeHash()
        }
        if (this.$disposeSettingsReaction) {
            this.$disposeSettingsReaction()
        }
        if (this.$disposeFullLoad) {
            this.$disposeFullLoad()
        }
        if (this.$debouncedFetchList) {
            this.$debouncedFetchList.cancel()
        }
        this.destroyLinkToList(void 0, forceClear)
    }

    @action
    private destroyLinkToList = (removeHash?: string, forceClear: boolean = false) => {
        const defferedLists = new Map()
        const hashes = removeHash ? new Set([removeHash]) : this.$hashCache

        hashes.forEach(hash => {
            const list = this.$apiStore.getLists().get(hash)
            if (!list) {
                return
            }
            list.usedCount = Math.max(0, list.usedCount - 1)
            if (list.usedCount === 0) {
                if (this.$disposeTimeout === 0 || forceClear) {
                    this.$apiStore.removeList(hash)
                } else {
                    defferedLists.set(hash, list)
                }
            }
        })

        if (defferedLists.size > 0) {
            setTimeout(() => {
                defferedLists.forEach((list, hash) => {
                    if (list.usedCount === 0) {
                        this.$apiStore.removeList(hash)
                    }
                })
            }, this.$disposeTimeout)
        }

        if (hashes === this.$hashCache) {
            this.$hashCache.clear()
        }
    }

    @computed
    public get isValid() {
        return !this.list.invalidatedItems
    }

    @computed
    public get list() {
        return this.$apiStore.getList(this.hash)
    }

    @computed
    public get originalItems() {
        return (this.list.invalidatedItems ? this.list.invalidatedItems : this.list.items) as Collections.List<T>
    }

    @computed
    public get items(): Collections.List<ListItem<T>> {
        const lastIndex = this.originalItems.length - 1
        if (this.isValid) {
            const result = []

            if (this.list.loadStatePrev.isPending()) {
                result.push({isFirst: true, isLast: false, loadState: this.list.loadStatePrev})
            } else if (this.list.hasMorePrev) {
                result.push({isFirst: true, isLast: false, load: bindArg(this.load, "Prev")})
            }

            result.push(...this.originalItems.map((item, index) => ({
                isFirst: index === 0,
                isLast: index === lastIndex,
                item: item as T
            })).toArray())

            const isFirst = result.length === 0

            if (this.list.loadStateNext.isPending()) {
                result.push({isFirst: isFirst, isLast: !isFirst, loadState: this.list.loadStateNext})
            } else if (this.list.hasMoreNext) {
                result.push({isFirst: isFirst, isLast: !isFirst, load: bindArg(this.load, "Next")})
            }

            return Collections.List<ListItem<T>>(result)
        } else {
            return Collections.List<ListItem<T>>(
                this.originalItems.map((item, index) => ({
                    isFirst: index === 0,
                    isLast: index === lastIndex,
                    item: item as T
                }))
            )
        }
    }

    public pull = () => {
        if (!this.hash) {
            return
        }

        void this.$apiStore.fetchList(this.hash, this.$endpoint, this.$options, this.paginationOptions, "Prev")
        void this.subscribeToLoadHandlers()
    }

    public loadNext = (limit?: number) => {
        return this.load("Next", limit)
    }

    public loadPrev = (limit?: number) => {
        return this.load("Prev", limit)
    }

    public load = (direction: ListDirection = "Next", limit?: number) => {
        if (!this.hash) {
            return
        }

        if (
            (direction === "Next" && !this.list.loadStateNext.isNone() && !this.list.hasMoreNext) ||
            (direction === "Prev" && !this.list.loadStatePrev.isNone() && !this.list.hasMorePrev) ||
            (direction === "Next" && this.list.loadStateNext.isPending()) ||
            (direction === "Prev" && this.list.loadStatePrev.isPending())
        ) {
            return
        }

        void this.$apiStore.fetchList(
            this.hash,
            this.$endpoint,
            this.$options,
            Object.assign({}, this.paginationOptions, limit ? {limit} : void 0),
            direction
        )
        void this.subscribeToLoadHandlers()
    }

    private async subscribeToLoadHandlers() {
        await this.whenComplete()
        if (this.loadStateNext.isCompleted()) {
            (this.$onSuccess || noop)()
        } else if (this.loadStateNext.isError()) {
            (this.$onError || noop)()
        }
    }

    public reloadList = (direction: ListDirection = "Next") => {
        return this.$apiStore.fetchList(
            this.hash,
            this.$endpoint,
            this.$options,
            Object.assign({}, this.paginationOptions),
            direction,
            true
        )
    }

    @action
    public resetList = () => {
        this.invalidateCache()
        this.loadNext()
    }

    @action
    public invalidateCache() {
        const newList = new EntitiesList()
        newList.items = new ObservableListClass((this.list.items.toArray()))
        newList.invalidatedItems = this.list.invalidatedItems
        newList.loadedEndpoint = this.list.loadedEndpoint
        this.$apiStore.getLists().set(this.hash, newList)

        this.$hashCache.forEach(hash => {
            const list = this.$apiStore.getLists().get(hash)
            if (hash !== this.hash && list && list.usedCount === 0) {
                this.$apiStore.getLists().delete(hash)
            }
        })
        this.$hashCache.clear()
        this.$hashCache.add(this.hash)
        this.$apiStore.getLists().set(this.hash, newList)
    }

    public replaceEntities = async (entities: T[]) => {
        this.$apiStore.listRemoveEntities(this.hash, this.originalItems.toArray())
        await this.$apiStore.listAppendEntities(this.hash, entities)
    }

    public removeEntities = (entities: T[]): number => {
        return this.$apiStore.listRemoveEntities(this.hash, entities)
    }

    public insertEntities = async (entity: T, index: number) => {
        await this.$apiStore.listAppendEntityByIndex(this.hash, entity, index)
    }

    public appendEntities = async (entities: T[]) => {
        await this.$apiStore.listAppendEntities(this.hash, entities)
    }

    public prependEntities = async (entities: T[]) => {
        await this.$apiStore.listPrependEntities(this.hash, entities)
    }

    public moveEntities = async (entities: T[]) => {
        this.$apiStore.listRemoveEntities(this.hash, entities)
        await this.$apiStore.listPrependEntities(this.hash, entities)
    }

    public addEntityToSet = (
        entity: T,
        insertMethod: "append" | "prepend" = "append",
        requestEntity?: BaseValue,
        endpoint: string = this.endpoint,
        saveEntity = false,
    ) => {
        return this.$apiStore.addEntityToList(this.hash, entity, endpoint, "update", requestEntity, insertMethod === "prepend", saveEntity)
    }

}
